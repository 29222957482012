
import { defineComponent } from '@nuxtjs/composition-api'
import get from 'lodash/get'

export default defineComponent({
  props: {
    title: { type: String, required: false, default: undefined },
    subtitle: { type: String, required: false, default: undefined },
    entries: { type: Array, required: true },
    colorTheme: { type: String, required: false, default: undefined },
  },
  setup() {
    return { get }
  },
})
