function* generateGroupedItems(config, items) {
  let group = null

  for (const item of items) {
    const { groupTypename, isHeader, isEntry } = config[item.__typename] || {}
    if (isHeader) {
      if (group) yield group
      group = { __typename: groupTypename, header: item, items: [] }
    } else if (isEntry) {
      if (group && group.__typename === groupTypename) {
        group.items.push(item)
      } else {
        if (group) yield group
        group = { __typename: groupTypename, items: [item] }
      }
    } else {
      if (group) {
        yield group
        group = null
      }
      yield item
    }
  }

  if (group) yield group
}

export function setupDynamicContentItems(items) {
  const config = {
    overviewDynamicContent_sectionHeader_BlockType: { groupTypename: 'overview_section_entries_group', isHeader: true },
    overviewDynamicContent_entry_BlockType: { groupTypename: 'overview_section_entries_group', isEntry: true },
    dynamicContent_foldout_BlockType: { groupTypename: 'foldout_entries_group', isEntry: true },
  }

  return { groupedItems: Array.from(generateGroupedItems(config, items)) }
}
