
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    title: { type: String, required: true },
    image: {
      type: Object,
      required: false,
      default: () => ({
        _xsUrl: 'images/fallback/608x414.png',
        _xsWidth: 608,
        _xsHeight: 414,
        _smUrl: 'images/fallback/350x238.png',
        _smWidth: 350,
        _smHeight: 238,
      }),
    },
    url: { type: String, required: true },
    buttonText: { type: String, required: true },
  },
})
