
import { computed, defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    title: { type: String, required: true },
    subtitle: { type: String, required: false, default: undefined },
    iconLeft: { type: String, required: false, default: undefined },
    iconRight: { type: String, required: false, default: undefined },
    duration: { type: Number, required: false, default: undefined },
    image: { type: Object, required: false, default: undefined },
    imageLazy: { type: Boolean, required: false, default: true },
    portrait: { type: Boolean, required: false, default: false },
    url: { type: String, required: false, default: undefined },
    size: { type: String, required: false, default: null, validator: (value) => ['large', 'medium', 'small', 'extraSmall', 'landscape'].includes(value) },
    theme: { type: String, required: false, default: undefined },
  },
  setup(props) {
    const time = computed(() => {
      const number = props.duration ? parseInt(props.duration) : 0

      // Extract hours, minutes, and seconds
      const hours = Math.floor(number / 3600)
      const remainingSeconds = number % 3600
      const minutes = Math.floor(remainingSeconds / 60)
      const seconds = remainingSeconds % 60

      // Add leading zeros
      const formattedHours = ('0' + hours).slice(-2)
      const formattedMinutes = ('0' + minutes).slice(-2)
      const formattedSeconds = ('0' + seconds).slice(-2)

      // Return the formatted time string
      if (hours > 0) {
        return formattedHours + ':' + formattedMinutes + ':' + formattedSeconds
      } else {
        return formattedMinutes + ':' + formattedSeconds
      }
    })
    return {
      time,
    }
  },
})
