
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    title: { type: String, required: false, default: undefined },
    buttonTitle: { type: String, required: false, default: undefined },
    buttonUrl: { type: String, required: false, default: undefined },
    hasBorder: { type: Boolean, required: false, default: true },
  },
})
