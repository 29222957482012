/*
 * Breakpoints:
 * xs: 640 - 1
 * sm: 768 - 1
 * md: 1024 - 1
 * lg: 1280 - 1
 * xl: 1750 - 1
 * 2xl and higher: 9999
 */

const breakpointsList = {
  // mx1: max 1 item on all breakpoints
  mx1: {
    9999: { perView: 1 },
  },
  // mx2: max 2 items on widest breakpoints
  mx2: {
    9999: { perView: 2 },
    1023: { perView: 1 },
  },
  // mx3: max 3 items on widest breakpoints
  mx3: {
    9999: { perView: 3 },
    1023: { perView: 2 },
    639: { perView: 1 },
  },
  // mx4: max 4 items on widest breakpoints
  mx4: {
    9999: { perView: 4 },
    1279: { perView: 3 },
    1023: { perView: 2 },
    767: { perView: 1 },
  },
}

export default breakpointsList
