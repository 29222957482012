
import { defineComponent } from '@nuxtjs/composition-api'
import get from 'lodash/get'

export default defineComponent({
  props: {
    items: { type: Array, required: true },
    portrait: { type: Boolean, required: false, default: false },
    slidesVisible: { type: Number, required: false, default: 2 },
  },
  data() {
    return {
      get,
      slickOptions: {
        slidesToShow: this.slidesVisible,
        arrows: true,
        responsive: [
          {
            breakpoint: 1366, // till 1366
            settings: {
              slidesToShow: 3,
              arrows: true,
            },
          },
          {
            breakpoint: 1280, // till 1280 (until desktop)
            settings: {
              slidesToShow: this.portrait ? 3 : 2,
              arrows: true,
            },
          },
          {
            breakpoint: 1024, // till 1024 (until tablet landscape)
            settings: {
              slidesToShow: 2,
              arrows: true,
            },
          },
          {
            breakpoint: 768, // till 768px (until tablet portrait)
            settings: {
              slidesToShow: this.portrait ? 2 : 1,
              arrows: false,
            },
          },
          {
            breakpoint: 640, // till 640px (until small)
            settings: {
              slidesToShow: 1,
              arrows: false,
            },
          },
        ],
      },
    }
  },
})
