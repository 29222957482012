export async function newsletterSubscribe(bridgeUrl, locale, emailAddress, recaptchaToken, origin, endpointPath) {
  const init = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    body: JSON.stringify({ email: emailAddress, origin, recaptchaToken }),
  }

  const url = `${bridgeUrl}/newsletter/${locale}/subscribe${endpointPath ? `?endpoint_path=${encodeURIComponent(endpointPath)}` : ''}`
  const response = await fetch(url, init)
  const result = await response.json()

  return result.success ? result.success : false
}
