import { render, staticRenderFns } from "./CardCover.vue?vue&type=template&id=3f41ce58&scoped=true"
import script from "./CardCover.vue?vue&type=script&lang=js"
export * from "./CardCover.vue?vue&type=script&lang=js"
import style0 from "./CardCover.vue?vue&type=style&index=0&id=3f41ce58&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f41ce58",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculePicture: require('/home/userapp/components/Molecule/Picture.vue').default,AtomIcon: require('/home/userapp/components/Atom/Icon.vue').default,AtomLink: require('/home/userapp/components/Atom/Link.vue').default})
