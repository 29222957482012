
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    title: { type: String, required: true },
    subtitle: { type: String, required: false, default: undefined },
    image: { type: Object, required: false, default: undefined },
    url: { type: String, required: true },
    backgroundColor: { type: String, required: false, default: 'bg-white' },
    icon: { type: String, required: false, default: undefined },
  },
})
